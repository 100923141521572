<template>
  <small v-if="loading">Aan het laden...</small>
  <small v-if="!invoice">Factuur niet gevonden.</small>
  <div v-else>
    <FacturatieFactoryNieuw type="CREDIT_NOTE" type-label="Credit Nota" :initial-value="initialValue">
      <p class="bg-blue-100 p-1 rounded border border-blue-200 max-w-md">
        Totaal prijs van factuur was: <b>&euro;&nbsp;{{ formatPrice(invoice.price_incl) }}</b>
      </p>
      <p class="bg-blue-100 p-1 rounded border border-blue-200 max-w-md">
        Factuur bedrag betaald: <b>&euro;&nbsp;{{ formatPrice(invoice.price_incl - invoice.amount_open) }}</b>
      </p>
    </FacturatieFactoryNieuw>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import FacturatieFactoryNieuw from '@/pages/facturatie/_factory/Nieuw.vue'

import { formatPrice } from '@/functions/formatNumber'
import { formatDocNumber } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'

const apiDataGet = computed(() => ({ type: 'INVOICE', journal_id: route.query?.journal_id, doc_number: route.params?.doc_number_linked }))
const { loading, data: invoice } = useGetApi('FINANCIAL_DOCS_INFO', apiDataGet)

const route = useRoute()
const initialValue = computed(() => {
  const result = {
    doc_number: null,
    doc_number_linked: invoice.value?.doc_number || null,
    client_id: invoice.value?.client_id || null,
    journal_id: invoice.value?.journal_id || null,
    products: [],
  }

  const price_incl = Number(invoice.value?.price_incl || 0)
  const price_excl = Number(invoice.value?.price_excl || 0)
  const vat = Number(invoice.value?.vat >= 0 ? invoice.value?.vat : Math.round(price_incl / price_excl))

  if (price_incl > 0) {
    result.products.push({
      description: `Terugbetaling factuur ${formatDocNumber(result.doc_number_linked || '', result.journal_id)}`.trim(),
      price_excl: formatPrice(price_incl / (1 + (vat / 100))),
      price: price_incl,
      vat,
    })
  }

  return result
})
</script>
